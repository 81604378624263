import { env } from 'src/env.mjs';

const portalPath = env.NEXT_PUBLIC_USER_PORTAL_PATH;

const Routes = {
    HOME: `/`,
    ERROR: `/error`,
    USER_SIGN_IN: (redirectUrl?: string) =>
        redirectUrl
            ? `/login?redirect_url=${encodeURIComponent(redirectUrl)}`
            : `/login`,
    SIGN_OUT: `${portalPath}/users/sign_out`,
    LOGIN: `/login`,
    FORGOT_PASSWORD: `${portalPath}/users/password/new`,
    CREATE_ACCOUNT: `/create-account`,
    NOTIFICATIONS: `${portalPath}/notifications`,
    WELCOME: `/create-account/welcome`,
    VERIFY_EMAIL: (email: string) =>
        `/verify-email?email=${encodeURIComponent(email)}`,

    HAIR_LOSS_INITIAL_QUIZ: `/initial/hair`,
    HAIR_LOSS_RENEWAL_QUIZ: `/renewal/hair`,
    WEIGHT_LOSS_INITIAL_QUIZ: `/initial/weight_loss`,
    WEIGHT_LOSS_RENEWAL_QUIZ: `/renewal/weight_loss`,
    SKIN_INITIAL_QUIZ: `/initial/skin`,
    SKIN_RENEWAL_QUIZ: `/renewal/skin`,
    SEX_INITIAL_QUIZ: `/initial/sex`,
    SEX_RENEWAL_QUIZ: `/renewal/sex`,
    MENTAL_HEALTH_QUIZ: `${portalPath}/quizzes/mental_health_quiz`,
    MENTAL_HEALTH_RENEWAL_QUIZ: '/renewal/mental_health',
    PROSTATE_INITIAL_QUIZ: `/initial/prostate`,
    PROSTATE_SYMPTOMS_QUIZ: `/symptoms/prostate`,
    DIABETES_LANDING_PAGE: '/diabetes',
    DIABETES_INITIAL_QUIZ: `/initial/diabetes`,
    DIABETES_RENEWAL_QUIZ: `/renewal/diabetes`,

    QUIZ_CONSULT: `/quiz_consult`,
    QUIZ_CONSULT_BOOKING: `/quiz_consult/booking`,
    MEDICAL_SUPPORT: `/medical-support`,
    MEDICAL_SUPPORT_TICKET: (ticketToken: string) =>
        `/medical-support?ticket=${ticketToken}`,
    MEDICAL_SUPPORT_MEDICATION: (medicationId: string) =>
        `/medical-support/medications/${medicationId}`,
    MEDICAL_SUPPORT_MEDICATION_STEP: (medicationId: string, stepId: number) =>
        `/medical-support/medications/${medicationId}/step/${stepId}`,
    MEDICAL_SUPPORT_MEDICATION_ARTICLES: (medicationId: string) =>
        `/medical-support/medications/${medicationId}/articles`,
    MEDICAL_SUPPORT_MEDICATION_OVERVIEW: (medicationId: string) =>
        `/medical-support/medications/${medicationId}/overview`,
    CONTACT_US: `${portalPath}/contact`,
    PRIVACY_POLICY: `/privacy`,
    TERMS_OF_USE: `/terms`,
};

export default Routes;
