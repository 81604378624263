'use client';

import { datadogRum } from '@datadog/browser-rum';
import { redirect } from 'next/navigation';
import { useEffect } from 'react';
import Routes from 'src/constants/Routes';

type ErrorPageProps = {
    error: Error & { digest?: string };
};

function ErrorPage({ error }: ErrorPageProps) {
    useEffect(() => {
        datadogRum.addError(error, {
            stack: error.stack,
            digest: error.digest,
        });
        redirect(Routes.ERROR);
    }, [error]);

    return null;
}

export default ErrorPage;
